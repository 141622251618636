@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("./fonts/Gilroy-Light.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Gilroy-ExtraBold"),
    url("./fonts/Gilroy-ExtraBold.otf") format("opentype");
  font-weight: normal;
}
  
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply font-GilroyLight text-pvr-darkblue;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label {
    @apply font-GilroyExtraBold leading-relaxed tracking-wide;
  }

  h1 {
    @apply text-2xl lg:text-4xl;
  }
  
  h2 {
    @apply text-xl lg:text-3xl;
  }

  h3 {
    @apply text-lg lg:text-2xl;
  }
  
  h4 {
    @apply text-base lg:text-xl;
  }

  h5 {
    @apply text-sm lg:text-lg;
  }

  h6 {
    @apply text-xs lg:text-base;
  }

  button {
    @apply leading-none;
  }

  label {
    @apply text-sm xl:text-base block uppercase tracking-wider my-0.5;
  }

  input[type=text],
  input[type=email],
  input[type=number],
  input[type=password] {
    @apply w-full h-10 border-2 border-pvr-darkblue rounded-full px-4 py-2 font-GilroyLight my-0.5 outline-none;
  }

  textarea {
    @apply w-full h-28 border-2 border-pvr-darkblue rounded-md px-4 py-2 font-GilroyLight my-0.5 outline-none;
  }

  input:disabled,
  select:disabled,
  .select.disabled,
  input[type=text]:disabled,
  input[type=email]:disabled,
  input[type=number]:disabled,
  input[type=password]:disabled,
  textarea:disabled {
    @apply bg-gray-100;
  }

  input[type=checkbox] {
    background-color: blue; 
  }

  input[type=checkbox]:checked {
    background-color: red; 
  }

  select {
    @apply w-full font-GilroyLight outline-none;
    background-color: #fff;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 100% 50%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
  }

  input::placeholder {
    @apply text-pvr-gray;
  }

  p, li {
    @apply text-base lg:text-lg my-2;
  }

  ul {
    @apply pl-8;

    li {
      @apply list-disc;
    }
  }
}

@layer components {
  .select {
    @apply w-full flex h-10 border-2 border-pvr-darkblue rounded-full px-4  font-GilroyLight my-0.5 outline-none;
  }

  .input {
    @apply w-full h-10 border-2 border-pvr-darkblue rounded-full px-4 py-2 font-GilroyLight my-0.5 outline-none;
  }

  .checkbox {
    @apply font-GilroyLight normal-case flex items-start text-base;

    span:nth-child(3) {
      @apply flex-1;
    }

    input[type=checkbox] {
      @apply absolute opacity-0;

      + span {
        @apply w-5 h-5 inline-block bg-contain mr-2 mt-1;
        background-image: url('img/unchecked.svg');
      }
    }
    
    input[type=checkbox]:checked {
      + span {
        @apply w-5 h-5 inline-block bg-contain mr-2 mt-1;
        background-image: url('img/checked.svg');
      }
    }
  }

  .radio {
    @apply font-GilroyLight normal-case flex items-center text-base;

    span:nth-child(3) {
      @apply flex-1;
    }

    input[type=radio] {
      @apply absolute opacity-0;

      + span {
        @apply w-6 h-6 block bg-cover bg-no-repeat;
        background-image: url('img/unselected.svg');
      }
    }
    
    input[type=radio]:checked {
      + span {
        @apply w-6 h-6 block bg-cover bg-no-repeat;
        background-image: url('img/selected.svg');
      }
    }
  }
}

@layer utilities {
  .large-text {
    @apply text-4xl xl:text-5xl my-6;
  }

  input,
  input[type=text],
  input[type=email],
  input[type=number],
  input[type=password],
  .select {
    &.error {
      @apply border-red-500 border-2;
    }
  }

  label.error {
    @apply text-red-500;
  }

  span.error {
    @apply text-red-500 px-4 mt-2 block text-sm;
  }
}

  .ReactModal__Overlay {
    background-color: rgba(6, 31, 64, 0.30) !important;
  }

  .ReactModal__Content {
    width: 100% !important;
    max-width: 720px !important;
    border: 4px solid #0D326A !important;
    border-radius: 4px !important;
  }

.sigContainer {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.splash-bkgd  {
  background-image: url('img/background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}